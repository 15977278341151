import type { FC } from 'react';
import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { Y50 } from '@atlaskit/theme/colors';

import { APP_NAV_SPACES_DROPDOWN_EXPERIENCE, ExperienceStop } from '@confluence/experience-tracker';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningContainer = styled.div({
	backgroundColor: token('color.background.warning', Y50),
	padding: token('space.200', '16px'),
	textAlign: 'center',
});

const i18n = defineMessages({
	viewAll: {
		id: 'spaces-dropdown.view-all',
		defaultMessage: 'View all spaces',
		description: 'Text for link to space directory',
	},
	error: {
		id: 'spaces-dropdown.error',
		defaultMessage: 'We are unable to show your spaces at this time. Refresh or try again later.',
		description: 'Displayed in case of error',
	},
});

export const SpacesDropdownError: FC<{ error: Error }> = ({ error }) => {
	const isNav4Enabled = useIsNav4Enabled();
	const experienceAttributes = useMemo(
		() => ({
			navVersion: isNav4Enabled ? '4' : '3',
		}),
		[isNav4Enabled],
	);
	return (
		<WarningContainer>
			<ExperienceStop
				name={APP_NAV_SPACES_DROPDOWN_EXPERIENCE}
				error={error}
				attributes={experienceAttributes}
			/>
			<FormattedMessage {...i18n.error} />
		</WarningContainer>
	);
};
