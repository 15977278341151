import type { FC } from 'react';
import React, { useContext } from 'react';
import { styled } from '@compiled/react';

import { N20, N50, N80 } from '@atlaskit/theme/colors';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { SpaceItemView } from '@confluence/entity-view';
import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { Link } from '@confluence/route-manager';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { SpaceStarButton } from '@confluence/action-buttons/entry-points/spaceStar';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { SpaceIcon } from '@confluence/icons/entry-points/SpaceIcon';
import type { Space } from '@confluence/space-utils/entry-points/useSpacesData';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { useFlyoutActions } from '@confluence/side-navigation/entry-points/flyoutStore';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';

type SpacesDropdownItemProps = {
	space: Space;
	isNav4Enabled: boolean;
	listId: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HoverableItemViewContainer = styled.li({
	position: 'relative',
	margin: 0,
	'--show-action': 0,
	'--action-button-color': token('color.icon.accent.gray', N50),
	'&:hover': {
		cursor: 'pointer',
		backgroundColor: token('color.background.neutral.subtle.hovered', N20),
		'--show-action': 1,
		'--action-button-color': token('color.icon.subtle', N80),
	},
	borderRadius: token('border.radius', '3px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemWrapper = styled.div({
	display: 'block',
	padding: '4px 16px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceLink = styled(Link)({
	position: 'absolute',
	height: '100%',
	width: '100%',
	top: 0,
	left: 0,
	fontSize: 0,
	background: 'transparent',
	border: 'none',
	zIndex: 0,
	'&:focus': {
		zIndex: 1,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&, &:active, &:visited, &:hover, &:focus': {
		textDecoration: 'none',
		outlineOffset: token('space.negative.025', '-2px'),
	},
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireSpacesMenuItemClickedAnalytics,
);

export const SpacesDropdownItem: FC<SpacesDropdownItemProps> = ({
	space,
	isNav4Enabled,
	listId,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { resetStickySearchRef } = useContext(AppNavigationContext);
	const { isExternalCollaborator: isGuest } = useIsExternalCollaborator();
	const { closeFlyout } = useFlyoutActions();

	const fireSpacesItemClickedAnalytics = useLazyClickAnalytics(
		createAnalyticsEvent,
		isNav4Enabled ? '4' : '3',
		{
			componentType: 'customItem',
			itemType: 'space',
			itemId: space.id,
			section: listId,
			isGuest,
		},
	);

	const onItemClick = () => {
		closeFlyout();
		resetStickySearchRef?.current?.();
		void fireSpacesItemClickedAnalytics();
	};

	const fireStarClickAnalytics = useLazyClickAnalytics(
		createAnalyticsEvent,
		isNav4Enabled ? '4' : '3',
		{
			componentType: 'customItemAction',
			itemId: 'star',
			parentItemType: 'space',
			parentItemId: space.id,
		},
	);

	const fireUnstarClickAnalytics = useLazyClickAnalytics(
		createAnalyticsEvent,
		isNav4Enabled ? '4' : '3',
		{
			componentType: 'customItemAction',
			itemId: 'unstar',
			parentItemType: 'space',
			parentItemId: space.id,
		},
	);

	const spaceAlias = space.alias || space.key!;

	const { accessStatus } = useSessionData();
	const allowStarring =
		accessStatus === AccessStatus.LICENSED_USE_ACCESS ||
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.EXTERNAL_COLLABORATOR_ACCESS;

	if (!space.id || !space.key) {
		return null;
	}

	if (isNav4Enabled) {
		const isStarred = Boolean(space.currentUser?.isFavourited);

		const starButton = allowStarring ? (
			<SpaceStarButton
				spaceId={space.id}
				spaceKey={spaceAlias}
				isStarred={isStarred}
				size="small"
				tooltipPosition="top"
				onStar={fireStarClickAnalytics}
				onUnstar={fireUnstarClickAnalytics}
				spacing="none"
			/>
		) : undefined;

		return (
			<MenuLinkItem
				href={SPACE_OVERVIEW.toUrl({ spaceKey: spaceAlias })}
				actions={isStarred ? starButton : undefined}
				actionsOnHover={!isStarred ? starButton : undefined}
				onClick={onItemClick}
				elemBefore={<SpaceIcon size="xsmall" src={space.icon?.path} />}
			>
				{space.name}
			</MenuLinkItem>
		);
	}

	return (
		<HoverableItemViewContainer data-testid="pages-dropdown-navigation-item">
			<SpaceLink
				onClick={onItemClick}
				name={SPACE_OVERVIEW.name}
				params={{ spaceKey: spaceAlias }}
				aria-label={space.name || ''}
			/>
			<ItemWrapper>
				<SpaceItemView
					space={space}
					analytics={{
						source: 'spacesDropdown',
						attributes: {
							spaceId: space.id,
						},
					}}
					buttonSize="small"
					tooltipPosition="top"
				/>
			</ItemWrapper>
		</HoverableItemViewContainer>
	);
};
